<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="badge()"
        badge-classes="bg-danger"
        icon="LBellIcon"
        size="21"
        :fill="themeColor ? themeColor : ''"
      />
    </template>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications && notifications.data"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-link
        v-for="(notification, index) in notificationsList"
        :key="index"
      >
        <b-media
          @click="readNotification(notification)"
        >
          <div class="d-flex align-items-center">
            <div
              :style="{ background: notification.read_at ? 'transparent' : 'red' }"
              class="notificationStatus"
            />
            <div class="ml-1">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ getMessage(notification) }}
                </span>
              </p>
            </div>
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import { BLink, BMedia, BNavItemDropdown } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      themeColor: localStorage.getItem('storageThemeColor'),
    }
  },
  computed: {
    notifications() {
      return this.$store.state.horizontalMenu.notificationList
    },
    notificationsList() {
      return this.$store.state.horizontalMenu.notificationList.data
    },
  },

  mounted() {
    // private channel
    // const channel = this.$pusher.subscribe(`private-notification-${getUserData().id}`)
    //
    // channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', status => {
    //   console.error(status)
    // })

    const channel = this.$pusher.subscribe(`notification-${getUserData().id}`)
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
      this.$store.commit('horizontalMenu/SET_NOTIFICATION_LIST', data)

      this.notificationAudio()
    })
  },

  created() {
    this.$store.dispatch('horizontalMenu/getNotificationList').then(res => {
      const { data } = res
      this.$store.commit('horizontalMenu/SET_NOTIFICATIONS_LIST', data)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
    })
  },

  methods: {
    getMessage($notification) {
      if (!$notification || !$notification.data) return ''
      return $notification.data.messages.message ?? ''
    },

    notificationAudio() {
      const notificationAudio = new Audio(require('@/assets/audio/notification.ogg'))
      notificationAudio.play()
    },
    readNotification(data) {
      if (data.read_at == null) {
        this.$store.dispatch('horizontalMenu/updateNotificationStatus', { id: data.id }).then(res => {
          const { data } = res.data
          this.$store.commit('horizontalMenu/SET_NOTIFICATION', data)

          // console.log(this.notificationList)
          // this.notificationList = data.data
          // console.log(this.notificationList)
        }).catch(err => {
          this.error = true
          this.errorNotification(this, err)
        }).finally(() => {
        })
      }
    },
    badge() {
      if (this.notifications && Array.isArray(this.notifications.data)) {
        return this.notifications.data.filter(item => (item.read_at === null || item.read_at === undefined)).length
      }
      return 0
    },
  },

  setup() {
    // const MODULE_NAME = 'notificationMessages'
    // if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, notificationModel)
    //
    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    // })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 10,
      wheelPropagation: false,
    }

    return {
      // MODULE_NAME,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style scoped>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up {
  right: -9px
}

.dropdown-toggle span span {
  background-color: #BB2124;
  color: #FFFFFF;
  top: 0px;
  left: 13px !important;
  height: 17px;
  width: 19px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 3px;
  font-size: 14px;
}

.notificationStatus {
  height: 12px;
  width: 12px;
  border-radius: 10px;
}
</style>
